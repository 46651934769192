/*global $*/
/*eslint no-undef: "error"*/

export default class TabsContact {
    classButton = 'tabs-contact__item'
    classButtonActive = 'tabs-contact__item-active'
    classMap = 'contact__map'
    classMapActive = 'contact__map-active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')
            $(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(`.${self.classButton}[data-block="${block}"]`).addClass(self.classButtonActive)

            $(`.${self.classMap}`).removeClass(self.classMapActive)
            $(`.${self.classMap}[data-block="${block}"]`).addClass(self.classMapActive)
        })
    }
}
