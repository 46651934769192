/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import HeaderFixed from '@/assets/js/Header/HeaderFixed/HeaderFixed'
import HeaderMobile from '@/assets/js/Header/HeaderMobile/HeaderMobile'
import MenuMobile from '@/assets/js/Menu/MenuMobile/MenuMobile'
import TabsButton from '@/assets/js/Tabs/TabsButton/TabsButton'
import ShowHidden from '@/assets/js/ShowHidden/ShowHidden'
import FaqItem from '@/assets/js/Faq/FaqItem'
import TabsContact from '@/assets/js/Tabs/TabsContact/TabsContact'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import Cookie from '@/assets/js/Cookie/Cookie'
import Map from '@/assets/js/Map/Map'
import form from '@/store/form'
import AppFormMain from '@/components/Form/FormMain/AppFormMain'
import AppFormPopupPrice from '@/components/Form/FormPopupPrice/AppFormPopupPrice'
import AppFormPopupConsult from '@/components/Form/FormPopupConsult/AppFormPopupConsult'
import AppFormReview from '@/components/Form/FormReview/AppFormReview'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-subscribe]')
    new HeaderFixed()
    new HeaderMobile()
    new MenuMobile()
    new TabsButton()
    new ShowHidden()
    new FaqItem()
    new TabsContact()
    new SwiperSlider()
    new Cookie()
    new Map()

    if(document.getElementById('form--main')!= null) {
        createApp(AppFormMain).use(form).use(Maska).mount('#form--main')
    }

    if(document.getElementById('form--price')!= null) {
        createApp(AppFormPopupPrice).use(form).use(Maska).mount('#form--price')
    }

    if(document.getElementById('form--consult')!= null) {
        createApp(AppFormPopupConsult).use(form).use(Maska).mount('#form--consult')
    }

    if(document.getElementById('form--review')!= null) {
        createApp(AppFormReview).use(form).use(Maska).mount('#form--review')
    }
})
