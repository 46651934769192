/*global $*/
/*eslint no-undef: "error"*/

export default class HeaderMobile {
    classButton = 'header-mobile--button'
    classButtonOpen = 'header__toggle-open'
    classHeader = 'header-mobile'
    classHeaderOpen = 'header-mobile-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).toggleClass(self.classButtonOpen)
            $(`.${self.classHeader}`).toggleClass(self.classHeaderOpen)
        })
    }
}
