/*global $*/
/*eslint no-undef: "error"*/

export default class MenuMobile {
    classButton = 'menu-mobile__button'
    classItem = 'menu-mobile__item'
    classItemOpen = 'menu-mobile__item-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classItem}`).toggleClass(self.classItemOpen)
        })
    }
}
