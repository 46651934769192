/*global $*/
/*eslint no-undef: "error"*/
export default class Map {
    classItem = 'map--item'
    classMapID = 'map--container-'
    classMapActive = 'map__map-active'
    classIframeID = 'map--iframe-'

    constructor() {
        let self = this
        let countItem = parseInt($(`.${self.classItem}`).length)
        let evts = ['click', 'mouseover', 'touchstart', 'touchmove']

        if (countItem > 0) {
            for (let i = 1; i <= countItem; i++) {
                let mapContainer = document.getElementById(self.classMapID + i)

                if (mapContainer !== null) {
                    let mapOptions = {
                        once: true,
                        passive: true,
                        capture: true
                    }
                    let mapLoaded = false

                    evts.forEach(function (item) {
                        mapContainer.addEventListener(item, () => {
                                if ((!mapLoaded) && (mapContainer)) {
                                    let mapIframe = document.getElementById(self.classIframeID + i)

                                    mapLoaded = true
                                    mapContainer.classList.add(self.classMapActive)
                                    mapIframe.setAttribute('src', mapIframe.getAttribute('data-src'))
                                    mapIframe.removeAttribute('data-src')
                                }
                            },
                            mapOptions
                        )
                    })
                }
            }
        }
    }
}
