/*global $*/
/*eslint no-undef: "error"*/

export default class HeaderFixed {
    classHeader = 'header'
    classFixed = 'header--fixed'
    classPush = 'header__push'
    classPushFixed = 'header__push-fixed'

    fixedBlocks (top, classHeader, classFixed) {
        let headerObj = $(`.${classHeader}`)
        let pushObj = $(`.${this.classPush}`)

        if (top >= 80) {
            headerObj.addClass(classFixed)
            pushObj.addClass(this.classPushFixed)
        } else {
            headerObj.removeClass(classFixed)
            pushObj.removeClass(this.classPushFixed)
        }
    }

    constructor () {
        let self = this

        self.fixedBlocks($(window).scrollTop(), self.classHeader, self.classFixed)

        $(window).scroll(function () {
            let top = parseInt($(this).scrollTop())
            self.fixedBlocks(top, self.classHeader, self.classFixed)
        })
    }
}
