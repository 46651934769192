/*global $*/
/*eslint no-undef: "error"*/

export default class TabsButton {
    classTab = "tabs-button--tab"
    classTabActive = "tabs-button__tab-active"
    classItem = "tabs-button--item"
    classItemActive = "price-item-active"

    constructor () {
        let self = this

        $(`.${self.classTab}`).click(function () {
            let number = $(this).data('number')
            $(`.${self.classTab}`).removeClass(self.classTabActive)
            $(`.${self.classTab}[data-number="${number}"]`).addClass(self.classTabActive)

            $(`.${self.classItem}`).removeClass(self.classItemActive)
            $(`.${self.classItem}[data-number="${number}"]`).addClass(self.classItemActive)
        })
    }
}
