<template lang="pug">
.form-popup.form-popup-100
  .h2.center(v-html="settings.title")
  .form-popup__fields
    .form-popup__field
      .input-text
        input.input-text__input(
          type="text"
          placeholder="Ваше имя *"
          v-model="fieldName"
          :class="{'input-text__input-error': v$.fieldName.$invalid && (fieldName.length > 0), 'input-text__input-complete': !v$.fieldName.$invalid && (fieldName.length > 0)}"
        )
    .form-popup__field
      .input-textarea
        textarea.input-textarea__input(v-model="fieldText" placeholder="Отзыв")

  .consent
    input.consent__input(type="checkbox" id="form-checkbox-4" v-model="consent" value="consent" checked="checked")
    label.consent__label(for="form-checkbox-4" v-html="settingConsent.text")

  .form-popup__button
    .button(:class="{'button-disable': v$.$invalid}" @click="getSend()")
      .button__name(v-html="settings.button")
</template>

<script>
/*global $*/
/*eslint no-undef: "error"*/

import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormReview',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      settings: {},
      settingConsent: {},
      files: '',
      consent: ['consent'],
      fieldName: '',
      fieldText: ''
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        $('.button-popup--success').click()
        this.Send({
          setting: {
            title: 'Заявка с формы',
            subject: 'Заявка с формы "Новый отзыв"'
          },

          fields: [
            {
              title: 'Имя',
              value: this.fieldName
            },
            {
              title: 'Отзыв',
              value: this.fieldText
            },
            {
              title: 'Id страницы',
              value: this.settings.fields.id
            },
            {
              title: 'Название страницы',
              value: this.settings.fields.pageName
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldText = ''
      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40)
      },

      fieldText: {
        required,
        minLength: minLength(5)
      },

      consent: {
        required
      }
    }
  },

  created () {
    this.settings = window.interSpecServis.FormReview
    this.settingConsent = window.interSpecServis.FormPopupConsent
  }
}
</script>
